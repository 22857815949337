// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js';

//Additional JS
import '@accessible360/accessible-slick/slick/slick.min';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import communities from './routes/community'
import about from './routes/about';
import contact from './routes/contact';
import search from './routes/search';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // Communities page
  communities,
  // About page
  about,
  // Contact page
  contact,
  // search results page
  search,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
