export default {
  init() {
    // JavaScript to be fired on about search results template
    
  },
  finalize() {
    // JavaScript to be fired on search results template after previous

  },
};
