let brandOptions = {
  threshold: 1,
}

let brandCallback = (entries) => {
  entries.forEach(entry => {
    let bodyClassList = document.body.classList;
    if (entry.isIntersecting /**||  entry.boundingClientRect.top > 0**/) {
      document.body.classList.remove('splash-inactive');
      return;
    }else {
      if(!bodyClassList.contains('splash-inactive')) {
        document.body.classList.add('splash-inactive');
      }
      return;
    }
  });
};

const brandObserver = new IntersectionObserver(brandCallback, brandOptions);

export default {
  init() {
    // JavaScript to be fired on the home page
    if(window.pageYOffset && !document.body.classList.contains('splash-inactive')) {
      document.body.classList.add('splash-inactive');
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    //brandObserver.observe(document.querySelector('#splash-brand_holder'));
    brandObserver.observe(document.querySelector('#home_splash'));
  },
};
