import google from 'google';
import * as ribbonBanner from '../../images/ribbonBanner.svg';
import * as mapMarkerShield from '../../images/mapMarker-shield.svg';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

//remove all child nodes
function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

//slice array into chunks
function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

//White Dots on slider
function slickDotsWhite(slick,index) {
  let hex = '#ffffff';
  let color = 'stroke="'+hex+'" fill="'+hex+'"';
  let circle = '<svg width="12" height="12" '+color+' viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="5" transform="rotate(90 6 6)" stroke-width="2"/></svg>';
  return '<button type="button"><span class="slick-dot-icon" aria-hidden="true">'+circle+'</span><span class="slick-sr-only">Go to slide ' + (index + 1) + '</span></button>';
}

//Create slide chunks array
function getScrollerArr(communityPosts) {
  const communitySlideChunks = sliceIntoChunks(communityPosts, 8);
  let communitySlideGroups = [];
  communitySlideChunks.forEach(chunk => {
    let groupSlides = [];
    if(chunk.length == 8) {
      groupSlides[0] = chunk.slice(0, 2); //2
      groupSlides[1] = chunk.slice(2, 3); //1
      groupSlides[2] = chunk.slice(3, 6); //3
      groupSlides[3] = chunk.slice(6, 8); //2
    }else if (chunk.length == 7) {
      groupSlides[0] = chunk.slice(0, 2); //2
      groupSlides[1] = chunk.slice(2, 3); //1
      groupSlides[2] = chunk.slice(3, 6); //3
      groupSlides[3] = chunk.slice(6, 7); //1
    }else if (chunk.length == 6) {
      groupSlides[0] = chunk.slice(0, 2); //2
      groupSlides[1] = chunk.slice(2, 3); //1
      groupSlides[2] = chunk.slice(3, 6); //3
    }else if (chunk.length == 5) {
      groupSlides[0] = chunk.slice(0, 2); //2
      groupSlides[1] = chunk.slice(2, 3); //1
      groupSlides[2] = chunk.slice(3, 5); //2
    }else if (chunk.length == 4) {
      groupSlides[0] = chunk.slice(0, 2); //2
      groupSlides[1] = chunk.slice(2, 3); //1
      groupSlides[2] = chunk.slice(3, 4); //1
    }else if (chunk.length == 3) {
      groupSlides[0] = chunk.slice(0, 2); //2
      groupSlides[1] = chunk.slice(2, 3); //1
    }else if (chunk.length == 2) {
      groupSlides[0] = chunk.slice(0, 2); //2
    }else if (chunk.length == 1) {
      groupSlides[0] = chunk.slice(0, 1); //1
    }
    communitySlideGroups.push(groupSlides);
  });
  return communitySlideGroups;
}

function getBlockDisplay(block) {
  let open = 
  '<div class="scroller-block pb-2 position-relative" data-reg="'+block.reg+'" data-rate="'+block.rate+'" data-life="'+block.life+'">' + 
    '<div class="scroller-block-inner position-relative w-100 h-100">';

  let front = 
  '<div class="scroller-block_front ratio w-100 position-relative h-100">' + 
    block.img;

  if(block.flag) {
    front += '<div class="scroller_construction-label" style="background-image: url('+ribbonBanner.default+');"><span class="fw-bold fs-sm">UNDER CONSTRUCTION</span></div>';
  }
  front += '</div>';

  let back = 
 '<div class="scroller-block_back position-absolute start-0 w-100 px-3">' + 
    '<div class="scroll-block_info text-end py-3">' + 
      '<h3 class="scroll-block_info-heading text-sans fw-medium">'+block.head+'</h3>' + 
      '<p class="scroll-block_info-city text-primary text-serif mb-0">'+block.city+', TX</p>' + 
      '<button type="button" class="btn btn-secondary btn-end" aria-label="Learn more about '+block.title+' - opens a modal dialog" data-bs-toggle="modal" data-bs-target="#communityModal" data-bs-post="'+block.id+'">Learn More</button>' + 
    '</div>' + 
  '</div>';

  let close = '</div></div>';

  return open+front+back+close; 
}

function updateScrollerContent(posts, scrollerID) {
  const scroll = document.getElementById(scrollerID);
  if(scroll.childNodes.length != 0) {
    $( document ).ready(function() {
      $('#'+scrollerID).slick('unslick');
    });
    removeAllChildNodes(scroll);
  }

  let groups = getScrollerArr(posts);
  groups.forEach(group => {
    let groupContent = '';
    group.forEach((slide, index) => {
      let style = index + 1;
      let slideContent = '<div class="scroller-slide ps-1 pe-1"><div class="scroller-style'+style+' scroller-content">';
      slide.forEach(block => {
        let blockContent = getBlockDisplay(block);
        slideContent += blockContent;
      });
      slideContent += '</div></div>';
      groupContent += slideContent;
    })
    scroll.innerHTML += groupContent;
  });
  $( document ).ready(function() {
    $('#'+scrollerID).slick({
      dots: true,
      arrows: true,
      dotsClass: 'slick-dots slick-dots-white',
      customPaging: slickDotsWhite,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 4,
      slidesToScroll: 1,
      variableWidth: true,
      speed: 600,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            centerPadding: '0',
            slidesToShow: 1,
            centerMode: true,
            variableWidth: false,
          },
        },
      ],
    });
  });
}

//create community modal object
function getModalObject(communityModal) {
  var modal = {
    'title': communityModal.querySelector('.modal-title'),
    'img': communityModal.querySelector('.modal-image'),
    'map': communityModal.querySelector('.modal-map'),
    'loc': communityModal.querySelector('.modal-location'),
    'desc': communityModal.querySelector('.modal-description'),
    'year': communityModal.querySelector('.modal-year'),
    'units': communityModal.querySelector('.modal-units'),
    'web': communityModal.querySelector('.modal-web'),
    'data': communityModal.querySelector('.modal-data'),
  }
  return modal;
}

//modify community modal content
function updateModalContent(post, communityModal) {
  var modal = getModalObject(communityModal);

  modal.title.innerHTML = post.title;
  modal.img.innerHTML = post.img;
  if(post.flag) {
    modal.img.innerHTML += '<div class="modal_construction-label" style="background-image: url('+ribbonBanner.default+');"><span class="fw-bold fs-sm">UNDER CONSTRUCTION</span></div>';
  }
  //modal.map.querySelector('#communityModalMap').innerHTML = post.address;
  modal.loc.innerHTML = post.city.toUpperCase()+', TX';
  modal.desc.innerHTML = post.desc;
  
  if(post.year) {
    modal.year.classList.remove('d-none');
    modal.year.removeAttribute('aria-hidden');
    modal.year.querySelector('.modal-num').innerHTML = post.year;
  } else {
    modal.year.classList.add('d-none');
    modal.year.setAttribute('aria-hidden', 'true');
    modal.year.querySelector('.modal-num').innerHTML = '';
  }
  
  if(parseInt(post.units) > 0) {
    modal.units.classList.remove('d-none');
    modal.units.removeAttribute('aria-hidden');
    modal.units.querySelector('.modal-num').innerHTML = post.units;
  } else {
    modal.units.classList.add('d-none');
    modal.units.setAttribute('aria-hidden', 'true');
    modal.units.querySelector('.modal-num').innerHTML = '';
  }
  
  if(post.web) {
    modal.web.classList.remove('d-none');
    modal.web.removeAttribute('aria-hidden');
    modal.web.querySelector('.modal-link').setAttribute('href', post.web);
  } else {
    modal.web.classList.add('d-none');
    modal.web.setAttribute('aria-hidden', 'true');
  }

  post.latlng = new google.maps.LatLng(post.lat, post.lng);
  mapModal.setCenter(post.latlng);
  markerModal.setPosition(post.latlng);
  markerModal.setTitle(post.title);
}

//Create infobox display
function getInfoboxContent(block) {
  let open = 
  '<div class="mapBlock position-relative container-fluid container-full px-0" style="width:500px;max-width:100%;" data-reg="'+block.reg+'" data-rate="'+block.rate+'" data-life="'+block.life+'">' + 
    '<div class="mapBlock-inner d-flex flex-column flex-md-row justify-content-between align-items-center w-100 px-2">';

  let right = 
  '<div class="mapBlock-right w-100 w-md-45 order-1 order-md-2">' +
    '<div class="ratio ratio-1x1">' +  
      block.img;

  if(block.flag) {
    right += '<div class="mapBlock_construction-label" style="background-image: url('+ribbonBanner.default+');"><span class="fw-bold fs-sm">UNDER CONSTRUCTION</span></div>';
  }
  right += '</div></div>';

  let left = 
 '<div class="mapBlock-left w-100 w-md-55 order-2 order-md-1">' + 
    '<div class="mapBlock_info text-end ps-1 ps-md-3 p-1 p-md-3">' + 
      '<h3 class="mapBlock_info-heading h5">'+block.head+'</h3>' + 
      '<p class="scroll-block_info-city text-primary text-serif mb-0">'+block.city+', TX</p>' + 
      '<button tabindex="0" type="button" class="btn btn-sm btn-secondary btn-end" aria-label="Learn more about '+block.title+' - opens a modal dialog" data-bs-toggle="modal" data-bs-target="#communityModal" data-bs-post="'+block.id+'">Learn More</button>' + 
    '</div>' + 
  '</div>';

  let close = '</div></div>';

  return open+left+right+close; 
}

//create map styles
let mapStylesArray = [
  {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#e9e9e9'},
      {'lightness': 17},
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f5f5f5'},
      {'lightness': 20},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 17},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 29},
      {'weight': 0.2},
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 18},
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 16},
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f5f5f5'},
      {'lightness': 21},
    ],
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#dedede'},
      {'lightness': 21},
    ],
  },
  {
    'elementType': 'labels.text.stroke',
    'stylers': [
      {'visibility': 'on'},
      {'color': '#ffffff'},
      {'lightness': 16},
    ],
  },
  {
    'elementType': 'labels.text.fill',
    'stylers': [
      {'saturation': 36},
      {'color': '#333333'},
      {'lightness': 40},
    ],
  },
  {
    'elementType': 'labels.icon',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'transit',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f2f2f2'},
      {'lightness': 19},
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.fill',
    'stylers': [
      {'color': '#fefefe'},
      {'lightness': 20},
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.stroke',
    'stylers': [
      {'color': '#fefefe'},
      {'lightness': 17},
      {'weight': 1.2},
    ],
  },
];

//Display google Map for Modal
let mapModal;
let markerModal;
let infowindowModal;
function initMapModal() {
  var latlng = new google.maps.LatLng(-34.031342, 18.577419); // default location
  var myOptions = {
    zoom: 16,
    center: latlng,
    mapTypeControl: false,
    styles: mapStylesArray,
  };

  mapModal = new google.maps.Map(document.getElementById('communityModalMap'), myOptions);
  infowindowModal = new google.maps.InfoWindow();

  markerModal = new google.maps.Marker({
    position: new google.maps.LatLng(-34.031342, 18.577419),
    title: 'Placeholder',
    map: mapModal,
    draggable: false,
    icon: new google.maps.MarkerImage(mapMarkerShield.default, null, null, null, new google.maps.Size(54,82)),
  });

  markerModal.addListener('click', () => {
    infowindowModal.setContent(markerModal.title);
    infowindowModal.open(mapModal, markerModal);
  });
}

let searchMap;
let searchBounds;
let searchMarkers;
let searchMarkerClusterer;

function CustomZoomControl(controlDiv, map) {
  var lineStyleH = 'width: 100%;height: 15%;background-color: #fff;position: absolute;top: 50%;left: 0;transform: translateY(-50%)';
  var lineStyleV = 'width: 15%;height: 100%;background-color: #fff;position: absolute;top: 0;left: 50%;transform: translateX(-50%)';

  // Set CSS for the control border
  var controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#7aadd3';
  controlUI.style.cursor = 'pointer';
  controlUI.style.marginBottom = '10px';
  controlUI.style.width = '3vw';
  controlUI.style.height = '3vw';
  controlUI.style.minWidth = '32px';
  controlUI.style.maxWidth = '65px';
  controlUI.style.minHeight = '32px';
  controlUI.style.maxHeight = '65px';
  controlUI.style.padding = '15%';
  controlUI.style.marginLeft = '1rem';
  controlDiv.appendChild(controlUI);

  // Set CSS for the control interior
  var controlText = document.createElement('div');
  controlText.style.position = 'relative';
  controlText.style.width = '100%';
  controlText.style.height = '100%';
  controlText.innerHTML = '<span style="'+lineStyleH+'"></span><span style="'+lineStyleV+'"></span>';
  controlUI.appendChild(controlText);

  // Setup the click event listeners
  controlUI.addEventListener('click', function () {
      map.setZoom(map.getZoom() + 1);
  });

  // Set CSS for the control border
  var controlUILeft = document.createElement('div');
  controlUILeft.style.backgroundColor = '#7aadd3';
  controlUILeft.style.border = '0px';
  controlUILeft.style.cursor = 'pointer';
  controlUILeft.style.marginBottom = '20px';
  controlUILeft.style.width = '3vw';
  controlUILeft.style.height = '3vw';
  controlUILeft.style.minWidth = '32px';
  controlUILeft.style.maxWidth = '65px';
  controlUILeft.style.minHeight = '32px';
  controlUILeft.style.maxHeight = '65px';
  controlUILeft.style.padding = '15%';
  controlUILeft.style.marginLeft = '1rem';
  controlDiv.appendChild(controlUILeft);

  // Set CSS for the control interior
  var controlTextLeft = document.createElement('div');
  controlTextLeft.style.position = 'relative';
  controlTextLeft.style.width = '100%';
  controlTextLeft.style.height = '100%';
  controlTextLeft.innerHTML = '<span style="'+lineStyleH+'"></span>';
  controlUILeft.appendChild(controlTextLeft);

  // Setup the click event listeners
  controlUILeft.addEventListener('click', function () {
      map.setZoom(map.getZoom() - 1);
  });
}

function initMapComSearch(locations) {
  const mapCenter = { lat: locations[0].lat, lng: locations[0].lng};
  const shieldIconSmall = new google.maps.MarkerImage(mapMarkerShield.default, null, null, null, new google.maps.Size(27,41));
  const shieldIcon = new google.maps.MarkerImage(mapMarkerShield.default, null, null, null, new google.maps.Size(54,82));
  searchMap = new google.maps.Map(document.getElementById('comSearchMap'), {
    zoom: 3,
    center: mapCenter,
    styles: mapStylesArray,
    disableDefaultUI: true,
  });
  const infoWindow = new google.maps.InfoWindow({
    content: '',
    maxWidth: 500,
    borderRadius: 0,
  });
  searchBounds = new google.maps.LatLngBounds();

  // Add some markers to the map.
  searchMarkers = locations.map((location) => {
    const marker = new google.maps.Marker({
      position: {lat: location.lat, lng: location.lng},
      title: location.title,
      icon: shieldIconSmall,
      id: location.id,
    });

    // open info window when marker is clicked
    marker.addListener('click', () => {
      infoWindow.setContent(getInfoboxContent(location));
      infoWindow.open({anchor: marker, shouldFocus: false});
    });

    searchBounds.extend(marker.getPosition());

    return marker;
  });

  searchMap.fitBounds(searchBounds);

  // Add a marker clusterer to manage the markers.
  const renderer = { render: ({ count, position }) =>
    new google.maps.Marker({
      label: { text: String(count), color: '#fff', fontSize: '1.5rem', className: 'cluster-label' },
      position,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      icon: shieldIcon,
    }),
  };

  searchMarkerClusterer = new MarkerClusterer({
    map: searchMap,
    markers: searchMarkers,
    renderer: renderer,
  });

  //custom zoom control
  var customZoomControlDiv = document.createElement('div');
  new CustomZoomControl(customZoomControlDiv, searchMap);
  customZoomControlDiv.index = 1;
  searchMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(customZoomControlDiv);
}

function initialize(communityPosts) {
  initMapModal();
  initMapComSearch(communityPosts);
}

function toggleFilterValue(filter) {
  let btn = filter.querySelector('.dropdown-toggle');
  let choices = filter.querySelectorAll('.dropdown-item');
  choices.forEach(function (choice) {
    choice.addEventListener('click', function() {
      if(this.classList.contains('active')) {
        btn.querySelector('.filter-text').innerHTML = btn.dataset.default.toUpperCase();
        btn.dataset.active = false;
        this.classList.remove('active');
      } else {
        let active = filter.querySelector('.dropdown-item.active');
        if(active) {
          active.classList.remove('active');
        }
        btn.querySelector('.filter-text').innerHTML = this.querySelector('.inactive').innerHTML;
        btn.dataset.active = this.dataset.val;
        this.classList.add('active');
      }
    });
  });
}

function getFilterParams(filters, inputs) {
  let params = {};
  filters.forEach(function(filter) {
    let btn = filter.querySelector('.dropdown-toggle');
    if(btn.dataset.active && btn.dataset.active != 'false' && btn.dataset.active != 'all') {
      params[btn.dataset.default] = btn.dataset.active;
    }
  });
  inputs.forEach(function(input) {
    if(input.value) {
      params[input.name] = input.value;
    }
  });
  return params;
}

function runCommunitySearch(params, communityPosts) {
  let resetPosts = true;
  let queryURL = '/communities?';
  Object.entries(params).forEach(([key, value]) => {
    queryURL += `${key}=${value}&`;
    if(`${value}`) {
      resetPosts = false;
    }
  });

  let newResults = [];

  if(!resetPosts) {
    history.pushState(null, '', queryURL.slice(0,-1));
    let testResults = communityPosts;
    for (const param in params) {
      newResults = [];
      testResults.forEach(function(post) {
        if(param == 'region') {
          if(post.reg == params.region) {
            newResults.push(post);
          }
        }else if(param == 'type') {
          if(post.rate == params.type) {
            newResults.push(post);
          }
        }else if(param == 'lifestyle') {
          if(post.life == params.lifestyle) {
            newResults.push(post);
          }
        }else if(param == 'keyword') {
          let keywordVal = params.keyword;
          let keyword = keywordVal.toLowerCase();
          let match = false;
          let postFields = ['title', 'loc', 'city', 'desc', 'year', 'units', 'web', 'reg', 'rate', 'life'];
          for (const field in postFields) {
            if(!match) {
              let testVal = post[postFields[field]];
              let test = testVal.toLowerCase();
              if(test.includes(keyword)) {
                match = true;
              }
            }
          }
          if(match) {
            newResults.push(post);
          }
        }else {
          if(post[param] == params[param]) {
            newResults.push(post);
          }
        }
      });
      testResults = newResults;
    }
  } else {
    newResults = communityPosts;
  }

  $( document ).ready(function() {
    $('#communities_search-scroller').slick('unslick');
    $('#communities_search-scroller').empty();

    if(newResults.length) {
      $('#communities_search-body').removeClass('no-results');
      let groups = getScrollerArr(newResults);
      groups.forEach(group => {
        let groupContent = '';
        group.forEach((slide, index) => {
          let style = index + 1;
          let slideContent = '<div class="scroller-slide ps-2 ps-md-0 pe-2"><div class="scroller-style'+style+' scroller-content">';
          slide.forEach(block => {
            let blockContent = getBlockDisplay(block);
            slideContent += blockContent;
          });
          slideContent += '</div></div>';
          groupContent += slideContent;
        })
        $('#communities_search-scroller').append(groupContent);
      });
      $('#communities_search-scroller').slick({
        dots: true,
        arrows: true,
        dotsClass: 'slick-dots slick-dots-white',
        customPaging: slickDotsWhite,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        speed: 600,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              centerPadding: '0',
              slidesToShow: 1,
              centerMode: true,
              variableWidth: false,
            },
          },
        ],
      });

      let newMarkers = [];
      searchBounds = new google.maps.LatLngBounds();

      newResults.forEach(function(result) {
        var testID = result.id;
        let newMarker = searchMarkers.find(element => element.id == testID);
        newMarkers.push(newMarker);
        searchBounds.extend(newMarker.getPosition());
      });

      searchMarkerClusterer.clearMarkers();
      searchMarkerClusterer.addMarkers(newMarkers);
      
      searchMap.fitBounds(searchBounds);
    } else {
      $('#communities_search-body').addClass('no-results');
    }
  });
}

function toggleFiltersDesktop() {
  let filtersToggle = document.querySelector('#flush-headingFiltersBtn');
  if(!filtersToggle.getAttribute('aria-expanded')) {
    filtersToggle.click();
  }
}

export default {
  init() {
    // JavaScript to be fired on community page template

    //load switcher
    let switcherBtns = document.querySelectorAll('.switcher-btn');
    switcherBtns.forEach(function (element) {
      element.addEventListener('click', function() {
        if(element.classList.contains('inactive')) {
          let sections = document.querySelectorAll(this.dataset.section + ' > div');
          sections.forEach(function(box){
            box.classList.toggle('active');
            box.classList.toggle('inactive');
            if(box.ariaHidden == 'true') {
              box.ariaHidden  = 'false';
            } else {
              box.ariaHidden = 'true';
            }
          });
          switcherBtns.forEach(function(btn){
            btn.classList.toggle('active');
            btn.classList.toggle('inactive');
          });
          searchMap.fitBounds(searchBounds);
        }
      });
    })
  },
  finalize() {
    // JavaScript to be fired on community page template after previous

    //Set Community Data
    let communityPosts = comData; // eslint-disable-line

    //Create community scroller
    const communityGrid = document.getElementById('communities_search-body_grid'); // eslint-disable-line
    updateScrollerContent(communityPosts, 'communities_search-scroller');

    //Community Modal
    const communityModal = document.getElementById('communityModal');
    communityModal.addEventListener('show.bs.modal', event => {
      let button = event.relatedTarget;
      let postID = button.getAttribute('data-bs-post');
      let post = communityPosts.find(element => element.id == postID);
      updateModalContent(post, communityModal);
    });

    //Run Google Maps
    initialize(communityPosts);

    let filterForm = document.querySelector('#communities_search-filters');
    let allFilters = document.querySelectorAll('.filter-group');
    allFilters.forEach(function(filter) {
      toggleFilterValue(filter);
    });

    let filterSubmit = filterForm.querySelector('.submit-group button');
    let filterFieldsGroup = filterForm.querySelector('.fields-group');
    let filterInputs = filterFieldsGroup.querySelectorAll('input');
    filterSubmit.addEventListener('click', function() {
      let params = getFilterParams(allFilters, filterInputs);
      runCommunitySearch(params, communityPosts);
      if(window.innerWidth < 1024) {
        document.querySelector('#flush-headingFiltersBtn').click();
      }
    });

    filterInputs.forEach(function (input) {
      input.addEventListener('keypress', function(e) {
        if(e.key === 'Enter') {
          let params = getFilterParams(allFilters, filterInputs);
          runCommunitySearch(params, communityPosts);
        }
      });
    });

    //Page Load Filters
    let urlCheck = paramSearch; // eslint-disable-line
    const communitySearchSection = document.getElementById('communities_search');
    if(urlCheck) {
      let urlParams = paramSearchVals; // eslint-disable-line
      runCommunitySearch(urlParams, communityPosts);
      communitySearchSection.scrollIntoView();
    }else {
      const queryString2 = window.location.search;
      const urlParams2 = new URLSearchParams(queryString2);
      if(urlParams2.get('view')) {
        communitySearchSection.scrollIntoView();
      }
    }

    //collapse filters and load map on mobile
    let filtersCollapseBtn = document.querySelector('#flush-headingFiltersBtn');
    let mapSwitcher = document.querySelector('#switcherMap');
    if(window.innerWidth < 1024) {
      if(mapSwitcher && mapSwitcher.classList.contains('inactive')) {
        mapSwitcher.click();
      }
      filtersCollapseBtn.click();
    }

    window.addEventListener('resize', toggleFiltersDesktop);
  },
};
