import Lightbox from 'bs5-lightbox';
import { CountUp } from 'countup.js';

let animateMeOptions = {
  root: null,
  rootMargin: '-80px 0px 0px',
  threshold: 0.25,
}

let animateMeCallback = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('animate__animated', 'animate__'+entry.target.dataset.animation);
      return; 
    }
  });
};

const animateMe = new IntersectionObserver(animateMeCallback, animateMeOptions);

let countMeOptions = {
  root: null,
  rootMargin: '-80px 0px 0px',
  threshold: 0.5,
}

let countMeCallback = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      let meCounter = new CountUp(entry.target.id, entry.target.dataset.counter, { separator: ',' });
      if (!meCounter.error) {
        meCounter.start();
      } else {
        console.error(meCounter.error);
      }
      return; 
    }
  });
};

const countMe = new IntersectionObserver(countMeCallback, countMeOptions);

function slickDotsWhite(slick,index) {
  let hex = '#ffffff';
  let color = 'stroke="'+hex+'" fill="'+hex+'"';
  let circle = '<svg width="12" height="12" '+color+' viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="5" transform="rotate(90 6 6)" stroke-width="2"/></svg>';
  return '<button type="button"><span class="slick-dot-icon" aria-hidden="true">'+circle+'</span><span class="slick-sr-only">Go to slide ' + (index + 1) + '</span></button>';
}

function slickDotsPrimary(slick,index) {
  let hex = '#003da5';
  let color = 'stroke="'+hex+'" fill="'+hex+'"';
  let circle = '<svg width="12" height="12" '+color+' viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="5" transform="rotate(90 6 6)" stroke-width="2"/></svg>';
  return '<button type="button"><span class="slick-dot-icon" aria-hidden="true">'+circle+'</span><span class="slick-sr-only">Go to slide ' + (index + 1) + '</span></button>';
}

function slickDotsSecondary(slick,index) {
  let hex = '#fa4616';
  let color = 'stroke="'+hex+'" fill="'+hex+'"';
  let circle = '<svg width="12" height="12" '+color+' viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="5" transform="rotate(90 6 6)" stroke-width="2"/></svg>';
  return '<button type="button"><span class="slick-dot-icon" aria-hidden="true">'+circle+'</span><span class="slick-sr-only">Go to slide ' + (index + 1) + '</span></button>';
}
function loadMoreNews(wrapper, selector, rest) {
  fetch(rest).then((response) => response.json()).then((data) => {
    data.forEach((item) => {
      let blurbArrLong = item.acf.blurb.split(' ');
      let blurbArr = blurbArrLong.slice(0, 15);
      let blurb = blurbArr.join(' ') + '...';
      let display = '<div class="col-12 col-md-4">' + 
        '<div class="article-block p-3">' + 
          '<h3 class="article-heading h5 border-2 border-bottom border-secondary pt-3 pb-4">'+item.title.rendered+'</h3>' +
            '<div class="article-body pt-3">' + 
              '<p class="article-publication fs-blurb mb-0 pb-0"><strong>'+item.acf.publication+'</strong></p>' + 
                '<p class="article-blurb fs-blurb">'+blurb+'</p>';
      if(item.acf.link) {
        display += '<a class="btn btn-secondary" href="'+item.acf.link+'" target="_Blank" aria-label="View More of '+item.title+' - opens a new tab">View More</a>';
      }
      display += '</div></div></div>';
      wrapper.innerHTML += display;
    });
  });
}
function doLoadMore(per, type, page, selector, order, orderby) {
  //let loading = document.querySelector(selector + ' #loading-message');
  var wrapper = document.querySelector(selector);
  let newPage = parseInt(page);
  newPage = newPage + 1;
  let fields = 'id,title,acf';
  let restbase = window.sage.rest_url + 'wp/v2/';
  let rest = restbase+type+'?page='+newPage+'&per_page='+per+'&_fields='+fields+'&orderby='+orderby+'&order='+order;
  if(type == 'article') {
    loadMoreNews(wrapper, selector, rest);
  }
}

export default {
  init() {
    // JavaScript to be fired on all pages
    // Create the measurement node
    var scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    console.warn(scrollbarWidth); // Mac:  15
    document.body.style.setProperty('--scrollbarWidth', scrollbarWidth+'px');

    // Delete the DIV 
    document.body.removeChild(scrollDiv);
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    //Run Animations
    let anyAnimated = document.querySelectorAll('.animate_me') !== null;
    if(anyAnimated) {
      const animated = document.querySelectorAll('.animate_me');
      animated.forEach((element) => {
        animateMe.observe(element);
      });
    }

    //Run Counters
    let anyCounted = document.querySelectorAll('.count_me') !== null;
    if(anyCounted) {
      const counted = document.querySelectorAll('.count_me');
      counted.forEach((element) => {
        countMe.observe(element);
      });
    }

    //Run Lightboxes
    let anyLightboxes = document.querySelectorAll('.tend-lightbox') !== null;
    if(anyLightboxes) {
      const lightboxes = document.querySelectorAll('.tend-lightbox');
      lightboxes.forEach(el => el.addEventListener('click', Lightbox.initialize));
    }

    //Run jQuery
    $( document ).ready(function() {
      //Run Slick
      $('.slick-carousel').each(function() {
        //set slick args
        let args = new Object();
        if($(this).data('nav') == 'dots') {
          args.dots = true;
          args.arrows = false;
        } else if($(this).data('nav') == 'arrows') {
          args.dots = false;
          args.arrows = true;
        } else if($(this).data('nav') == 'both') {
          args.dots = true;
          args.arrows = true;
        } else if($(this).data('nav') == 'none') {
          args.dots = false;
          args.arrows = false;
        }

        if(args.dots) {
          if($(this).data('theme') == 'white') {
            args.dotsClass = 'slick-dots slick-dots-white';
            args.customPaging = slickDotsWhite;
          } else if($(this).data('theme') == 'primary') {
            args.dotsClass = 'slick-dots slick-dots-primary';
            args.customPaging = slickDotsPrimary;
          } else if($(this).data('theme') == 'secondary') {
            args.dotsClass = 'slick-dots slick-dots-secondary';
            args.customPaging = slickDotsSecondary;
          }
        }

        if($(this).data('loop') == 'on') {
          args.infinite = true;
        }
        if($(this).data('autoplay') == 'on') {
          args.autoplay = true;
        }
        if($(this).data('speed')) {
          args.autoplaySpeed = $(this).data('speed');
        }
        if($(this).data('fade') == 'on') {
          args.fade = true;
        }
        if($(this).data('slides')) {
          args.slidesToShow = $(this).data('slides');
        }
        if($(this).data('scroll')) {
          args.slidesToScroll = $(this).data('scroll');
        }
        if($(this).data('width') == 'variable') {
          args.variableWidth = true;
        }
        args.speed = 600;
        if($(this).data('moves')) {
          args.speed = $(this).data('moves');
        }
        args.swipe = true;
        args.swipeToSlide = true;
        args.touchMove = true;
        args.touchThreshold = 20;

        if($(this).data('center')) {
          args.centerPadding = $(this).data('center');
          args.centerMode = true;
        }

        if($(this).data('mobile') == 'center') {
          args.responsive = [
            {
              breakpoint: 1023,
              settings: {
                centerPadding: '40px',
                slidesToShow: 1,
                centerMode: true,
              },
            },
          ]
        }
        //run slider
        $(this).slick(args);
      });
    });

    //Run Load More
    let anyLoadMores = document.querySelectorAll('[data-loadmore="on"]') !== null;
    if(anyLoadMores) {
      const loadMores = document.querySelectorAll('[data-loadmore="on"]');
      loadMores.forEach(el => el.addEventListener('click', function() {
        document.querySelector(this.dataset.holder).classList.toggle('d-none');
        doLoadMore(this.dataset.per, this.dataset.type, this.dataset.page, this.dataset.selector, this.dataset.order, this.dataset.orderby);
        let page = parseInt(this.dataset.page);
        let next = page+1;
        if(next < parseInt(this.dataset.max)) {
          document.querySelector(this.dataset.holder).classList.toggle('d-none');
          this.dataset.page = next.toString();
        }
      }));
    }
  },
};
