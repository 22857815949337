import google from 'google';
import * as mapMarkerShieldLines from '../../images/mapMarker-shieldLines.svg';

let contactMap;
let mapStylesArray = [
  {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#e9e9e9'},
      {'lightness': 17},
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f5f5f5'},
      {'lightness': 20},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 17},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 29},
      {'weight': 0.2},
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 18},
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 16},
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f5f5f5'},
      {'lightness': 21},
    ],
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#dedede'},
      {'lightness': 21},
    ],
  },
  {
    'elementType': 'labels.text.stroke',
    'stylers': [
      {'visibility': 'on'},
      {'color': '#ffffff'},
      {'lightness': 16},
    ],
  },
  {
    'elementType': 'labels.text.fill',
    'stylers': [
      {'saturation': 36},
      {'color': '#333333'},
      {'lightness': 40},
    ],
  },
  {
    'elementType': 'labels.icon',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'transit',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f2f2f2'},
      {'lightness': 19},
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.fill',
    'stylers': [
      {'color': '#fefefe'},
      {'lightness': 20},
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.stroke',
    'stylers': [
      {'color': '#fefefe'},
      {'lightness': 17},
      {'weight': 1.2},
    ],
  },
];

/**Revised Simplified Styles**/
mapStylesArray = [
  {
    'featureType': 'all',
    'elementType': 'labels.text.fill',
    'stylers': [
      {'saturation': 36},
      {'color': '#333333'},
      {'lightness': 40},
    ],
  },
  {
    'featureType': 'all',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {'visibility': 'on'},
      {'color': '#ffffff'},
      {'lightness': 16},
    ],
  },
  {
    'featureType': 'all',
    'elementType': 'labels.icon',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.fill',
    'stylers': [
      {'color': '#fefefe'},
      {'lightness': 20},
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.stroke',
    'stylers': [
      {'color': '#fefefe'},
      {'lightness': 17},
      {'weight': 1.2},
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'administrative.locality',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'simplified'},
    ],
  },
  {
    'featureType': 'administrative.neighborhood',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f5f5f5'},
      {'lightness': 20},
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f5f5f5'},
      {'lightness': 21},
      {'visibility': 'simplified'},
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#dedede'},
      {'lightness': 21},
    ],
  },
  {
    'featureType': 'road',
    'elementType': 'geometry',
    'stylers': [
      {'visibility': 'simplified'},
    ],
  },
  {
    'featureType': 'road',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'simplified'},
    ],
  },
  {
    'featureType': 'road',
    'elementType': 'labels.text',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 17},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 29},
      {'weight': 0.2},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'on'},
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.icon',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 18},
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'simplified'},
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#ffffff'},
      {'lightness': 16},
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'transit',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#f2f2f2'},
      {'lightness': 19},
    ],
  },
  {
    'featureType': 'transit',
    'elementType': 'labels',
    'stylers': [
      {'visibility': 'off'},
    ],
  },
  {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {'color': '#e9e9e9'},
      {'lightness': 17},
    ],
  },
];

function initMapContact(position, panWidth) {
  const mapCenter = position;
  const shieldIcon = new google.maps.MarkerImage(mapMarkerShieldLines.default, null, null, null, new google.maps.Size(133,164));
  contactMap = new google.maps.Map(document.getElementById('contactMap'), {
    zoom: 15,
    center: mapCenter,
    styles: mapStylesArray,
    disableDefaultUI: true,
  });

  if (window.screen.width >= 1024) {
    contactMap.panBy(panWidth, -82);
  } else {
    contactMap.panBy(0, 82);
  }

  const icon = shieldIcon;
  const marker = new google.maps.Marker({
    position: position,
    icon: icon,
    map: contactMap,
  });

  if(marker) {
    //holding
  }
}

function initialize(mapPosition, panWidth) {
  initMapContact(mapPosition, panWidth);
}

export default {
  init() {
    // JavaScript to be fired on contact page template

  },
  finalize() {
    // JavaScript to be fired on contact page template after previous

    //Run Google Maps
    let mapData = document.getElementById('contactMapData');
    let mapPosition = {lat: parseFloat(mapData.dataset.lat), lng: parseFloat(mapData.dataset.lng)};
    let panWidth = mapData.offsetWidth/-4;
    initialize(mapPosition, panWidth);
  },
};
