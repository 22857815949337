export default {
  init() {
    // JavaScript to be fired on about page template
    
  },
  finalize() {
    // JavaScript to be fired on about page template after previous

  },
};
